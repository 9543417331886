<template>
  <v-card class="mx-auto" outlined>
    <div id="inspire">
      <v-data-table
          :headers="headers"
          :items="branches"
          sort-by="count"
          class="elevation-1"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-arrow-left',
            nextIcon: 'mdi-arrow-right',
            itemsPerPageOptions: [5, 10, 15, 50, 100]
          }"
          :items-per-page="10"
          :hide-default-footer="branches.length <= 0"
      >

        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>
              <p color="white" class="mb-0">{{ $t("OPEN_BRANCHES_FOR_APPLY") }}</p>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="90vh">
              <template v-slot:activator="{  }">
                <v-col cols="auto" class="pa-1">

                  <v-btn
                      :small="!$vuetify.breakpoint.mdAndUp"
                      :fab="!$vuetify.breakpoint.mdAndUp"
                      color="primary"
                      class="mx-2"
                      @click="showScopeTreeFn()">
                    <span v-if="$vuetify.breakpoint.mdAndUp">{{ $t('SCOPE') }}</span>
                    <v-icon :right="$vuetify.breakpoint.mdAndUp">mdi-navigation</v-icon>
                  </v-btn>
                </v-col>

              </template>
              <v-card outlined>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-card-title class="justify-center">
                      <span class="headline white--text text-thin">{{
                          formTitle
                        }}</span>
                  </v-card-title>


                </v-form>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>

        <template v-slot:item.branchName="{ item }">
          {{ serverTranslate(item.branchName) }}
        </template>

        <template v-slot:item.facultyName="{ item }">
          {{ serverTranslate(item.facultyName) }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom v-if="!item.activeForApply">
            <template v-slot:activator="{ }">
              <v-btn icon v-on="item.activeForApply">
                <v-icon
                    meduim
                    v-if="!item.activeForApply"
                    @click="toggleActivationItem(item)"
                >toggle_off
                </v-icon
                >
              </v-btn>
            </template>
            <span>{{ $t("ACTIVATE") }}</span>
          </v-tooltip>

          <v-icon
              color="success"
              v-if="item.activeForApply"
              meduim
              @click="toggleActivationItem(item)"
          >toggle_on
          </v-icon
          >
        </template>

        <template v-slot:footer.page-text="props">
          {{ props.pageStart }} - {{ props.pageStop }} {{ $t('PAGE_TEXT') }} {{ props.itemsLength }}
        </template>
        <template v-slot:no-data>
          <v-alert icon="warning" class="mt-2" color="light5">
            {{ $t("NO_DATA") }}
          </v-alert>
        </template>
      </v-data-table>
      <pr-confirmDialog ref="confirm"></pr-confirmDialog>
      <pr-scope :key="componentKey" v-if="showScopeTree == true" v-on:setScope="onScopeChanged" :scopeFullPath="false"/>

    </div>
  </v-card>
</template>

<script>
import serverTranslationsMixin from "@/mixins/serverTranslations.ts";
import globalMethodsMixin from "@/mixins/globalMethods.ts";
import ConfirmDialog from "@/components/shared/ConfirmDialog";
import scope from "@/components/shared/ScopeTree.vue"

export default {
  name: "pr-certification-types",
  components: {
    "pr-confirmDialog": ConfirmDialog,
    "pr-scope": scope
  },
  mixins: [serverTranslationsMixin, globalMethodsMixin],
  data() {
    return {
      showScopeTree: false,
      componentKey: 0,
      dialog: false,
      valid: true,
      showAlert: false,
      errorMessages: "",
      branches: [],
      branchesIds: [],
    };
  },
  computed: {
    direction: function () {
      return this.$store.state.direction;
    },

    headers: function () {
      return [
        {
          text: this.$t("ORDER"),
          align: "start",
          sortable: true,
          value: "count"
        },
        {
          text: this.$t("BRANCH"),
          align: "start",
          sortable: true,
          value: "branchName"
        },
        {
          text: this.$t("FACULTY"),
          align: "start",
          sortable: true,
          value: "facultyName"
        },
        {
          text: this.$t("ACTIONS"),
          value: "actions",
          sortable: false,
          width: "150px"
        }
      ];
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  created() {
    this.initialize();
  },

  methods: {
    onScopeChanged(changed) {
      if (changed) this.initialize();
    },
    showScopeTreeFn() {
      this.showScopeTree = true;
      this.showBranchesCount = false;
      this.componentKey += 1;
    },
    initialize() {
      this.$http.get("/api/general_data/get_faculty_branches/").then(
          res => {
            this.branches = res.data.data.branchesList;
          },
          err => {
            this.showToasted("error", err.response.data.message);
          }
      );
    },

    toggleActivationItem(item) {
      const newObj = {
        branchId:item.id,
        activeForApply: !item.activeForApply,
        facultyId:item.facultyId,
        progHrId:item.progHrId
      };
      this.$http.put(`/api/general_data/update_active_branches_for_apply/`,newObj).then(
          res => {
            this.initialize();
          },
          err => {
            this.showToasted("error", err.response.data.message)
            this.initialize();
          }
      );
    },

  }
};
</script>
