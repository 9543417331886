export default {
  state: {
    scope: [
      {
        id: 10,
        path: "SystemInfo/sectors"
      },
      {
        id: 11,
        path: "SystemInfo/certificationsType"
      },
      {
        id: 13,
        path: "Reports/SectorReports"
      },
      {
        id: 12,
        path: "SystemInfo/Certifications"
      },
      {
        id: 14,
        path: "SystemInfo/courses"
      },
      {
        id: 1,
        path: "AcademicCourses/courses"
      },
      {
        id: 18,
        path: "ApprovalRules"
      },
      {
        id: 17,
        path: "StudentData/ManageApplications"
     },
      {
        id: 19,
        path: "ReportsAndStatistics/RecommendedStudents"
      }
      ,
      {
        id: 20,
        path: "StudentData/FeesPayment"
      },
      {
        id: 21,
        path: "ReportsAndStatistics/AllStudents"
      },
      {
        id: 22,
        path: "StudentData/StudentNominate"
      },
      {
        id: 26,
        path: "StudentData/PullingStudentData"
      },
      {
        id: 23,
        path: "ReportsAndStatistics/ApplicationStatus"
      },
      {
        id: 27,
        path: "Administration/Calendar"
     },
     {
      id: 28,
      path: "Administration/Services"
    },
    {
      id: 29,
      path: "Administration/Mails"
    },
      {
      id: 30,
        path: "ReportsAndStatistics/GeneralStatistic"
    },
    {
      id: 31,
        path: "StudentData/ManageLinkingOtherSystem"
    },
    {
      id: 32,
        path: "Administration/News"
    },
    {
      id: 33,
      path: "StudentData/SendingNotifications"
    }
    ,{
      id: 34,
      path: "Administration/MainRules"
    },
    {
       id: 35,
       path: "StudentData/StudentDataManagement"
     },
    {
       id: 36,
       path: "Administration/FacultiesBranchesManagement"
     },

    ],
    expansionPanels: [
      {
        panel: "SystemInfo",
        index: 1
      },
      {
        panel:"StudentData",
        index:14
      },
      {
        panel:"Administration",
        index:3,
      },
      {
        panel: "ApprovalRules",
        index: 15
      },
      {
        panel: "ReportsAndStatistics",
        index: 16
      },
    ],
    selectedApplicationId: "",
    activeExpansionPanel: "",
    appLevels : localStorage.getItem("appLevels") || [],
    scopeErrorMessage: "عفوا هذا التطبيق لا يعمل على هذا المجال|Sorry, this application doesn't work on this scope",
  },

  mutations: {
    changeActiveAppId(state: any, payload: any) {
      if (payload.path === "home") {
        state.selectedApplicationId = "";
        state.activeExpansionPanel = "";
      } else {
        if(state.scope.find(e => e.path == payload.path))
          state.selectedApplicationId = state.scope.find(e => e.path == payload.path).id;
      }
    },
    getOpenPanel(state: any, payload: any) {
      state.activeExpansionPanel = state.expansionPanels.find(
        e => e.panel == payload.panel
      ).index;
    }
  }
};
