const translationsAr = {
  VALIDATIONS: {
    EMAIL: "بريد الكتروني غير صالح" ,
    MAX_LENGTH: "الحد الأقصى {max} حرف",
    INVALID_NATIONAL_ID:  "رقم قومي غير صالح",

  },
  _lang: "English",
  LANGUAGE_NAME: "عربي",
  USERNAME: "اسم المستخدم",
  PASSWORD: "كلمة المرور",
  LOGIN: "دخول",
  SYSTEM_NAME: "مجلس الجامعات الخاصة والأهلية",
  COPY_RIGHT: "جميع الحقوق محفوظة",
  CITC: "انطلاق لتقنية الاتصالات والمعلومات",
  CITC_BRIEF: "CITC",
  MANS: "جامعة المنصورة",
  CONTACT_US: "اتصل بنا",
  CHANGE_LANGUAGE: "تغيير اللغة",
  ACCOUNT: "الحساب",
  NOTIFICATIONS: "الإشعارات",
  SCOPE: "تغيير المجال",
  ACADEMIC_YEAR_SEMESTER: "تغيير الفصل الدراسي والعام الأكاديمي",
  PROFILE: "الصفحة الشخصية",
  LOGOUT: "تسجيل الخروج",
  FORGOT_PASSWORD: "نسيت كلمة المرور؟",
  CLOSE: "غلق",
  SUCCESSED_LOGIN: "تم الدخول بنجاح",
  USERNAME_MAX: "اسم المستخدم يجب ألا يتعدى 70 حرفاً",
  USERNAME_REQUIRED: "من فضلك أدخل اسم المستخدم",
  PASSWORD_MAX: "كلمة المرور يجب ألا تتعدى 20 حرفاً",
  PASSWORD_REQUIRED: "من فضلك أدخل كلمة المرور",
  ACADEMIC_SEMESTER: "الفصل الدراسي",
  ACADEMIC_YEAR: "العام الأكاديمي",
  PERIORITY: "الأولويه",
  SAVE: "حفظ",
  CANCEL: "إلغاء",
  CREDENTIAL_ERROR: "اسم المستخدم أو كلمة المرور غير صحيحة",
  NOT_FOUND_SERVER_ERROR: "حدث خطأ، برجاء الاتصال بالدعم الفني",
  SECTORS: "القطاعات",
  AR_NAME: "الاسم بالعربية",
  EN_NAME: "الاسم بالإنجليزية",
  IS_ACTIVE: "فعال",
  ADD_NEW: "إضافة",
  EDIT: "تعديل",
  DELETE: "حذف",
  ADD_SECTOR: "إضافة قطاع",
  YES: "نعم",
  NO: "لا",
  COUNT: "العدد لكل صفحة",
  SUCCESS: "تم الحفظ بنجاح",
  REQUIRED:"{field} مطلوب",
  REQUIRED_FIELD: "هذا الحقل مطلوباً",
  ACTIVE: "فعال",
  NOT_ACTIVE: "غير فعال",
  ACTIVATION: "تفعيل",
  NO_DATA: "لا توجد بيانات",
  ARABIC_FIELD: "أدخل حروف عربية فقط",
  ENGLISH_FIELD: "أدخل حروف انجليزية فقط",
  ADD_CERTIFICATE_TYPE: "إضافة نوع شهادة",
  EDIT_CERTIFICATE_TYPE: "تعديل نوع شهادة",
  CERTIFICATE_TYPES: "أنواع الشهادات",
  ACTIONS: "التحكم",
  MAX_FIELD: "عدد حروف هذا الحقل يجب أن لا تتعدى ",
  MIN_FIELD: "عدد حروف هذا الحقل يجب أن لا يقل عن ",
  CONFIRM: "تأكيد",
  CONFIRMATION_MESSAGE: "رسالة تأكيد",
  DELETE_CONFIRMATION: "هل تريد تنفيذ عملية الحذف؟",
  CONFIRMATION_UPDATE_RULE: "هل انت متأكد من تعديل القاعدة حتى فى حالة تطبيقها وتحويل المرشحين للرفض والقبول على نفس القاعده الى تحت المراجعه ؟",
  PREVENT_NUMBERS_IN_FIRST: "يجب أن لا يبدأ هذا الحقل بأرقام",
  EDIT_SECTOR: "تعديل قطاع",
  ACTIVATE: "تفعيل",
  DEACTIVATE: "الغاء التفعيل",
  NEED_HELP: "هل تريد مساعدة ؟",
  CERTIFICATIONS: "الشهادات",
  CERTIFICATE_NAME: "اسم الشهادة",
  CERTIFICATE_TYPE: "نوع الشهادة",
  CERTIFICATE_COUNTRY: "بلد الحصول على الشهادة",
  STATUS: "الحالة",
  REASON:"السبب",
  SEND_FEEDBACK: "إرسال",
  YEAR: "السنة",
  RESET: "تفريغ الحقول",
  PRINT:'طباعه',
  DOWNLOAD:'تحميل',
  SEARCH: "بحث",
  VIEW: "عرض",
  TOTAL: "مجموع الشهادة",
  OPENED: "مفتوح",
  CLOSED: "مغلق",
  ROWS_PER_PAGE_TEXT: "الصفوف لكل صفحة:",
  PAGE_TEXT: "من",
  ADD_CERTIFICATE: "إضافة شهادة",
  EDIT_CERTIFICATE: "تعديل شهادة",
  PREVENT_SPECIAL_CHARACTER_FIELD: "يجب أن لا يحتوي هذا الحقل على علامات خاصة",
  FILTER_CHANGED_MESSAGE:
    "تم عرض نتيجة البحث السابقة لتغيير قيم البحث دون الضغط على زر البحث",
  CANCEL_SAVE_CERTIFICATION: "هل أنت متأكد من إلغاء حفظ الشهادة؟",
  OPEN: "فتح",
  COURSE_NAME: "اسم المقرر",
  COURSE_DEGREE: "درجة المقرر",
  COURSES: "المقررات",
  WITHOUT_CERTIFICATE: "بدون شهادة",
  WITH_CERTIFICATE: "على شهادة",
  OPEN_CERTIFICATE: "شهادة مفتوحة",
  CLOSED_CERTIFICATE: "شهادة مغلقة",
  CERTIFICATE_STATUS: "حالة الشهادة",
  DELETE_COURSE_CERTIFICATE: "حذف الشهادة من على المقرر",
  TOTAL_MIN: "ينبغي أن يزيد مجموع الشهادة عن 0",
  TOTAL_MAX: "ينبغي ألا يزيد مجموع الشهادة عن 999999",
  ACADEMIC_YEAR_CERTIFICATES: "شهادات العام الأكاديمي: ",
  ACADEMIC_YEAR_COURSES: "مقررات العام الأكاديمي: ",
  PULL_CERTIFICATIONS:"سحب شهادات",
  PULL: "سحب",
  PULL_CERTIFICATIONS_INFO_CONFIRMATION: "سيتم سحب الشهادات بمقرراتها من",
  PULL_CERTIFICATIONS_DELETE_CONFIRMATION: "مع حذف الشهادات الموجودة على نفس نوع الشهادة المسحوبة للعام الأكاديمي: ",
  PULL_EVEN_IF: "حتى إن وجدت عليها مقررات ",
  PULL_CERTIFICATIONS_MESSAGE: "حذف الشهادات التى تمت إضافتها مسبقاً على",
  FROM: "من ",
  TO: "إلى ",
  SHOW: "عرض",
  BACK: "عودة",
  ACADEMIC_YEAR_CLOSED_CERTIFICATES: "الشهادات المغلقة للعام الأكاديمي: ",
  ACADEMIC_YEAR_FROM_TO_VALIDATION: "يجب أن لا يساوي العام الأكاديمي العام الأكاديمي الآخر",
  ADVANCED_SEARCH:"بحث متقدم",
  ALL: "الكل",
  CERTIFICATE_NUMBER:"عدد الشهادات",
  STUDENTS_COUNT:"عدد الطلاب",
  COURSES_COUNT:"عدد المقررات",
  CERTIFICATE_DATA:"بيانات الشهادة",
  SEARCH_BY: "البحث ب",
  CERTIFICATE_RELATED_TO_COURSE: "الشهادات المرتبطة بالمقرر",
  COURSES_RELATED_TO_CERTIFICATE: "المقررات المرتبطة بالشهادة",
  COURSE_DETAILS: "تفاصيل المقرر",
  COURSE_NAME_AR: "اسم المقرر بالعربية",
  COURSE_NAME_EN: "اسم المقرر بالإنجليزية",
  EDIT_COURSE: "تعديل مقرر",
  ADD_COURSE: "إضافة مقرر",
  LOCKED_CERTIFICATE:"لا يمكن فتح او تعديل او مسح شهاده نظرا لوجود طلاب عليها",
  FOR: "الخاصة بـ",
  IMPORTANT_NOTES: "ملاحظات هامة",
  IMPORTANT_NOTES_TEXT:
    " هذه البطاقة مجرد اخطار للطالب بالكلية المتقدم لها ولا تعتبر مستنداً" +
    " لقيد الطالب بها ",

  UserData: {
    PROFILE: "الملف الشخصي",
    PROFILE_EDIT: "تعديل الملف الشخصي",
    PERSONAL_DATA: "البيانات الشخصية",
    CONTACT: "بيانات التواصل",
    CERTIFICATE: "بيانات المؤهل",
    AVATAR: "الصورة الشخصية",
    AR_NAME: "الإسم بالعربية",
    EN_NAME: "الإسم بالإنجليزية",
    NATIONALITY: "بلد الجنسية",
    NATIONAL_ID: "الرقم القومي",
    PASSPORT: "جواز السفر",
    GENDER: "النوع",
    MALE: "ذكر",
    FEMALE: "أنثى",
    BIRTHDAY: "تاريخ الميلاد",
    PHONE: "رقم التليفون",
    MOBILE: "رقم الموبايل",
    ADDRESS: "العنوان",
    BIRTHPLACE:"محل الميلاد",
    COUNTRY: "الدولة",
    STATE: "المحافظة / الولاية",
    CITY: "المدينة",
    EMAIL: "البريد الإلكتروني",
    CERTIFICATE_TYPE: "نوع الشهادة",
    CERTIFICATE_DEPT: "الشعبة",
    CERTIFICATE_YEAR: "عام الحصول عليها",
    CERTIFICATE_RESULT: "المجموع الكلي / الإعتبارى",
    CERTIFICATE_PERC: "النسبه",
    CERTIFICATE_RESULT_MAX: "المجموع الكلي / الإعتبارى يجب أن لا يزيد عن 9999",
    CERTIFICATE_EGY_MAX: "المجموع الكلي / الإعتبارى يجب أن لا يزيد عن 410",
    CERTIFICATE_PERC_MAX: "النسبه يجب أن لا تزيد عن 100",
    CERTIFICATE_EQ_TYPE: "نوع الشهاده المعادلة",
    CERTIFICATE_OTHER: "إسم الشهادة الأجنبية",
    CERTIFICATE_FOREIGN_TYPE: "إسم الشهادة الأجنبية",
    CANCEL_CONFIRMATION: "هل تريد العودة لصفحة الملف الشخصي؟",
    PIC_DELETE_CONFIRMATION: "هل تريد حذف وإستبدال الصورة؟",
    SAVE_CONFIRMATION:
      "هل تريد حفظ وإستبدال بيانات الملف الشخصي، بالبيانات المدخلة؟",
    CHANGE_PASSWORD: "تغيير كلمة المرور",
    NEW_PASSWORD: "كلمة المرور الجديدة",
    CONFIRM_NEW_PASSWORD: "تأكيد كلمة المرور الجديدة",

  },
  ADD_APPROVAL_RULE: "إضافة قاعدة قبول جديدة",
  EDIT_APPROVAL_RULE:"تعديل قاعدة القبول",
  APPROVAL_RULE_ADDED: "تم إضافة قاعدة قبول بنجاح",
  APPROVAL_RULE: "قاعدة القبول",
  APPROVAL_RULES: "قواعد القبول",

  APPROVAL_RULES_GRID_TITLE: "قواعد قبول العام الاكاديمي {year} الفصل {semester}",
  ACADANIC_YEAR:" العام الاكاديمي ",
  FACULTY: "الكلية",
  COUNT_ACCEPTED_STUDENTS:'أعداد الطلاب المقبولين',
  COUNT_REFUSED_AS_ACCEPTED_IN_OTHER_STUDENTS:'أعداد الطلاب المقبولين بكليه اخرى ',
  COUNT_WAITING_LIST_STUDENTS:'أعداد الطلاب قيد الإنتظار ',
  COUNT_REFUSED_STUDENTS: 'أعداد الطلاب المرفوضين',
  COUNT_DRAFT_STUDENTS:'أعداد الطلبات  مسوده',
  COUNT_UNDER_REVIEW_STUDENTS:'أعداد المتقدمين قيد المراجعه',
  COUNT_UNDER_REVIEW_NOFEES_STUDENTS:'أعداد الطلاب المتقدمين قيد المراجعه(بدون تطبيق رسوم)',
  COUNT_PULLED_SIS_STUDENTS:'أعداد الطلاب المنقولين لنظام ابن الهيثم لشئون الطلاب',
  COUNT_INCOMPLETE_STUDENTS:'أعداد الطلاب المطلوب إكمال طلابتهم',
  COUNT_STUDENT:'مجموع',
  OVER_ALL_COUNT_STUDENT:'إجمالى عدد الطلاب ',
  CERTIFICATE: "الشهاده",
  SPECIALIZATION:"التخصص",
  UNIVERSITY: "الجامعة",
  SEMESTER: "الفصل الدراسي",
  TOTAL_DEGREE: "المجموع الكلي",
  CERTIFIED_DEGREE: "المجموع المعتمد",
  STUDENTS_NUMBERS: "الأماكن المتاحة بالكلية",
  STUDENTS_NUMBERS_FOR: "عدد الطلاب ل",
  UNIVERSITY_NAME: "الجامعة",
  FACULTY_NAME: "الكلية",
  YEAR_NAME: "السنة",
  SEMESTER_NAME: "الفصل الدراسي",
  ALLOWED_TOTALDEGREE: "مجموع الشهادة المسموح به",
  ALLOWED_STUDENTCOUNT: "عدد الطلبة المسموح بهم",
  RECOMMENDED_ACCEPTED: "عدد المرشحين للقبول",
  RECOMMENDED_REFUSED: "عدد المرشحين للرفض",

  NUMBER_FIELD: "يجب أن يحتوى هذا الحقل على أرقام فقط",
  INT_NUMBER_FIELD: "يجب أن يحتوى هذا الحقل على أرقام صحيحة فقط",
  TOTAL_DEGREE_MAX: "المجموع الكلي يجب أن لا يزيد عن 999999.99",
  STUDENTS_NUMBERS_MAX: "عدد الطلاب يجب أن يكون أكبر من 0 ولا يزيد عن 100000",
  BRANCHES_NUMBERS_MAX: "مجموع عدد الطلاب للأفرع لا يجب أن يكون أكبر من عدد الطلاب الكلي",
  COMPLETE_DATA_ERROR: "من فضلك قم بإكمال البيانات بشكل صحيح لإتمام الحفظ",
  STUDENT_NOMINATE_APPLICATION:"ترشيح الطلاب المتقدمين",
  MANAGE_STUDENTS_APPLICATIONS:"إدارة طلبات الإلتحاق",
  MANAGE_STUDENTS_DATA:" البيانات الأساسيه للطلاب ",
  DEFAULT_STUDENTS_COUNT:"المجموع الإفتراضي للقبول",
  APPLY_ACCEPTANCE_RULE:"تطبيق قاعدة القبول",
  ACCEPT_APPLICATIONS:"قبول جميع الطلبات",
  REJECT_APPLICATIONS:"رفض  جميع الطلبات ",
  APPLY_ACCEPTANCE_RULE_TITLE:"عند تطبيق القاعدة يتم تقسيم الطلاب وفقاً للقاعدة من حيث القبول والرفض دون التأثير على الطلب",
  APPLY_RULE_CONFIRM:"هل تريد تنفيذ قاعدة القبول؟",
  ACCEPT_ALL_APPLICATION_CONFIRM:" هل انت متأكد من قبول جميع الطلبات المرشحة للقبول  ؟",
  REFUSE_ALL_APPLICATIONS_CONFIRM:"هل انت متاكد من رفض جميع الطلبات المرشحة للرفض ؟",
  HELP:"للمساعدة",
  ACCEPT_CANDIDATES:"الطلاب المرشحون للقبول",
  REJECT_CANDIDATES:"الطلاب المرشحون للرفض",
  CASES:[
      "مسودة",
      "تحت المراجعة",
      "مقبول",
      "مرفوض",
      "مطلوب إكماله",
      "مرشح للقبول",
      "مرشح للرفض",
      " تحت المراجعة",
      "تم النقل الى شئون طلاب ",
      "مقبول بكليه اخرى ",
      "قيد الإنتظار"
  ],
  DRAFT_STATUS:"مسودة",
  UNDER_REVIEW_STATUS:"تحت المراجعة",
  ACCEPTED_STATUS:"مقبول",
  REFUSED_STATUS:"مرفوض",
  TO_BE_COMPLETED: "مطلوب إكماله",
  RECOMMENDED_ACCEPTED_STATUS:"مرشح للقبول",
  RECOMMENDED_REFUSED_STATUS:"مرشح للرفض",
  UNDER_REVIEW_NOFEES_STATUS:"تحت المراجعة بدون تطبيق رسوم",
  PULLED_TO_SIS_STATUS:"منقولين لشئون طلاب",
  REFUSED_AS_ACCEPTED_IN_OTHER_STUDENTS:"مقبول بكليه اخرى ",
  WAITING_LIST:"قيد الانتظار",
  ALL_COUNT_STUDENT:"اجمالى الطلاب",
  SHOW_CHART:"عرض الرسم البيانى",
  APPLICATION_CASES:"حالة الطلب",
  IDENTITY:"رقم الهوية",
  APPID:"رقم الطلب",
  WithNAMETEXT:"بحث باسم الطالب",
  WithIDENTITYTEXT:"بحث برقم الهوية",
  WITHAPPID:"بحث برقم الطلب",
  APPLICATIONS:"طلبات الالتحاق",
  APPLICATIONS_LIST_TITLE:" الطلاب المتقدمين العام الاكاديمي {year} الفصل {semester}",
  USERS_LIST_TITLE:" البيانات الاساسيه للطلاب بنظام التقدم ",
  APPLICATION_EXPORTING_TITLE_STUDENT:"الطلاب المقبولين والمنقولين لشئون الطلاب ",
  APPLICATION_EXPORTING_TITLE:"الطلاب المقبولين والمنقولين لشئون طلاب  العام الاكاديمي {year} الفصل {semester}",
  STATUS_STUDENTS_AFTER_EXPORTING:"الطلاب المقبولين/ المرفوضين لنظام الجامعات الخاصة والاهلية",

  SELECT_ALL:"اختيار الكل",
  EXPORT_TO_PRIVATE_UNIVERSITY:"التصدير لنظام الجامعات الخاصة والاهلية",
  STUDENT_NAME:"اسم الطالب",
  APPLY_DATE:"تاريخ التقدم",
  APPLY_APPLICATION_DATE:"تاريخ تقديم الطلب ",
  STUDENT_NID:"رقم الهوية",
  END:"إنهاء",
  SHOW_APPLICATION_DETAILS:"عرض بيانات الطلب",
  IMAGE_ATTACHED: "الصورة المرفقة",
    ADMISSION_DATA: {
    APPLICATION_ID: "رقم المسلسل ",
    APPLICATION_DATE: "تاريخ الطلب",
    APPLICATION_TIME: "وقت الطلب",
    NEXT: "التالي",
    BACK: "السابق",
    SAVE: "إرسال",
    SEND: "إرسال",
    DRAFT: "حفظ كمسودة",
    CANCEL: "إلغاء",
    GO_TO_APP_LIST_CONFIRMATION:
      "هل تريد الذهاب لصفحة طلبات التقديم الخاصة بك؟",
    TERMS:
      "اقر ان كافة المعلومات الموضحة في بيان التقديم صحيحة ومطابقة تماما للمستندات الشخصية الخاصة بي وأتحمل مسؤولية اي خطأ بها دون اي مسؤولية علي النظام او المراجعين",
    UPLOAD_IMG: "تغيير الصورة",
    IMAGE_ATTACHED: "الصورة المرفقة",
    APP_STATUS: "حالة الطلب",
    APP_STATUSES: [
      "مسودة",
      "تحت المراجعة",
      "مقبول",
      "مرفوض",
      "مطلوب إكماله",
      "مرشح للقبول",
      "مرشح للرفض",
      "تحت المراجعه",
      "تم النقل لنظام شؤون الطلاب ",
      "مقبول بكلية اخرى ",
    ],
    STEP1: {
      NAME: "البيانات الشخصية",
      AR_FULLNAME: "الاسم كامل باللغة العربية",
      EN_FULLNAME: "الاسم كامل باللغة الإنجليزية",
      ID: "رقم الهوية",
      IDTYPE: "",
      ID_IMAGE: "صورة الهوية",
      NATIONALID: "رقم قومي",
      PASSPORT: "جواز سفر",
      IDHINT: "الرقم القومي أو جواز السفر",
      TYPE: "النوع",
      FEMALE: "انثي",
      MALE: "ذكر",
      PHONE: "رقم الموبايل",
      TELEPHONE: "رقم التليفون",
      EMAIL: "البريد الإلكتروني",
      BIRTHDATE: "تاريخ الميلاد",
      IMAGE: "الصورة الشخصية",
      COUNTRY: "الدولة",
      STATE: "المحافظة/الولاية",
      CITY: "المدينة",
      NATIIONALITY: "بلد الجنسية",
      ADDRESS: "العنوان",
      BIRTHPLACE:"محل الميلاد",
      CONTACTS: "ييانات الاتصال",
      PERSONAL_DATA: "البيانات الشخصية",
      BIRTH_CERT:"صورة شهادة الميلاد",
      GUARDIAN_CARD:"صورة بطاقة ولي الأمر",
      MILITARY_FORM:"نموذج التجنيد"
    },
    STEP2: {
      NAME: "المؤهل",
      CERTIFICATION: "نوع الشهادة",
      MAJOR: "التخصص",
      YEAR: "سنة المؤهل",
      SUM: "المجموع",
      OTHER_SUM: "المجموع المعتمد",
      EQUIVALENT_CERT_TYPE: "نوع شهادة المعادلة",
      CERT_FROM_COUNTRY: "من دولة",
      CERTIFICATE_IMAGE: "صورة المؤهل",
      CERTIFICATE_FILE: "ملف  المؤهلات السابقه ",
      CERTIFICATE_IMAGE2: "صورة المؤهل 2",
      CERTIFICATE_IMAGE3: "صورة المؤهل 3",
      RECEIPT_IMAGE: "صورة ايصال الدفع",
      STU_RESIDENCE_IMAGE: "صورة إثبات إقامة الطالب",
      LEGAL_GUARDIAN_RESIDENCE_IMAGE: "صورة إثبات إقامة ولى الامر ",
      CERTIFICATE_NAME: "اسم الشهادة",
      TOTAL_MIN: "ينبغي أن يزيد مجموع الشهادة عن 0",
      TOTAL_MAX: "ينبغي ألا يزيد مجموع الشهادة عن 999999"
    },
    STEP3: {
      NAME: "بيانات التقدم",
      ACDYEAR: "العام الدراسي",
      SEMESTER: "الفصل الدراسي",
      FACULTY: "الكلية",
      BRANCH:"الفرع",
      QUOTA:"الحصة",
      MESSAGE:
        "لإكمال طلبك يرجي إختيار الكلية والموافقة علي الأحكام والشروط المعرفة أدناه",
      TERMS: "الشروط والأحكام",
      CHECK: "أوافق علي",
      SAVE: "إرسال",
      CANCEL: "إلغاء",
      NEXT_CONFIRMATION:"سيتم حفظ بيانات الطلب كمسودة حتى تتمكن من دفع رسوم الطلب الكترونيا وارسال طلبك. موافق؟"

    },
    STEP4: {
      NAME: "الدفع الإلكتروني",
      EXPIRYPERIOD:"صالح لمدة",
      TABLETITLE:"بيانات الدفع",
      PERIOD:"5 ساعات ",
      PAID:"مدفوع",
      REFERENCENUM:"الرقم المرجعي",
      PAIDDATE:"تاريخ الدفع",
      PAYMENT_TIME:"وقت الدفع",
      TITLE:
        "لكي تتمكن من إرسال طلب التقدم يرجى سداد رسوم الطلب إلكترونياً عن طريق خدمة فوري أو بنك مصر",
      PAYMENT_PRICE:"بيانات الدفع المستحقة هي",
      TAXS:"بالإضافة إلى عمولة الدفع",
      TECHNICAL_SUPPORT:"في حالة حدوث مشكلة ، يرجى الاتصال بالأرقام التالية:",
      ONLINE_PAYMENT:"للدفع إلكترونياً",
      CLICK_HERE:"اضغط هنا",
      LE:"جنيه",
      HAS_REFRENCE_NOT_PAID:"طلبك # {paymentNum} معلق صالح لمدة 3 أيام فقط من تاريخ أخذ الرقم المرجعي {paymentDate} يمكنك دفع الرسوم من أي ماكينة دفع فوري أو من خلال (فيزا/بنك مصر) للدفع من خلالهم",
      HAS_REFRENCE_PAID: "طلبك # {paymentNum} تم دفعه بنجاح",
      EXPIRED:"طلبك # {paymentNum} أصبح منتهي",
      NOTE: "ملاحظة: في حالة استخراج رقم مرجعي ولم يتم الدفع خلال 3 أيام تستطيع إعادة طلب رقم مرجعي للدفع مرة أخرى من خلال الرابط الذي يظهر أمامك و سيتم إنشاء رقم أخر حتى تتمكن من الدفع عليه"
    }
  },
  APPLICATION_TITLE: "طلب إلتحاق العام الاكاديمي {appYear} الفصل {appSemeter}",
  NATIONALITY: "بلد الجنسية",
  APPLICATION_ACTIONS: "إجراءات",
  APPLICATION_STATUS: "إجراءات",
  ACCEPT_APPLICATION: "قبول الطلب",
  ACCEPT_APPLICATION_CONFIRM: "هل انت متأكد من أنك تريد قبول الطلب؟",
  REJECT_APPLICATION: "رفض الطلب",
  WAITING_APPLICATION: "قيد الإنتظار ",
  REJECT_APPLICATION_CONFIRM: "هل انت متأكد من أنك تريد رفض الطلب؟",
  WAITING_APPLICATION_CONFIRM: "هل انت متأكد من أنك تريد وضع الطلب قيد الانتظار؟",
  FEEDBACK_APPLICATION: "مراسلة الطالب",
  FEEDBACK_APPLICATION_CONFIRM: "هل انت متأكد من أنك تريد مراسلة الطالب؟",
  MESSAGE: "الرسالة",
  SENDING_GROUP:"ارسال جماعى",
  SENDING_GROUP_NOTIFICATION:"ارسال اشعار جماعى",
  SENDING_NOTIFICATION:"ارسال اشعار",
  GROUP_CONTROL:"تحكم جماعى",
  MAINTAIN_APPLICATION_STATUS:"الحفاظ على حالة الطلب",
  COMPLETION_REQUIRED:"مطلوب اكمالة",
  NOTIFICATIONS_MESSAGE:"ملحوظة:سيتم ارسال الاشعار بشكل جماعى لكافة الطلاب  بجميع الصفحات",
  DATA_LOADING: "جاري تحميل البيانات ...",
  EDIT_SUBMITTED_TOTAL: "تعديل المجموع المعتمد",
  EDIT_QUOTA:"تعديل الحصة",
  EDIT_STUDENT_EMAIL: "تعديل البريد الإلكترونى للطالب",
  EDIT_STUDENT_CARD_ID: "تعديل رقم الهويه للطالب ",
  noSpecialLetter: "رقم الهوية يجب يحتوي على حروف إنجليزية وأرقام فقط",
  BRANCH:"الفرع",
  OPEN_BRANCHES_FOR_APPLY:"التحكم فى  فروع الكليات المتاحه للتقديم ",
  STUDENTS_COUNT_IN_BRANCH: "عرض عدد الطلبة بفروع الكلية",
  ACCEPT:"موافق",
  FEES_PAYMENT:"دفع رسوم التقديم للطلبة",
  APPLICATION_NUM:"رقم الطلب",
  PAY_FEES:"{title} رسوم الطلب",
  APPLICATION_FEES:"رسوم الطلب",
  PAY_FEE_CONFIRM:"هل تريد تنفيذ عملية {pay} رسوم الطلب رقم {applicationId} للطالب {studentName} صاحب رقم هوية {idCard}؟",
  STUDENT_NUM:"رقم الطالب",
  PAYMENT_STATUS:"حالة الدفع",
  OTHER:"أخرى",
  HIDE_SHOW_COLUMN:"عرض وإخفاء أعمدة الجدول",
  REPORT:"تقرير",
  EXPORT:"تصدير",
  DEPOSIT_DATE:"تاريخ الإيداع",
  DEPOSIT_NUM:"رقم الإيداع",
  DEPOSIT_IMAGE:"صورة الإيداع",
  IMAGE_SIZE_VALIDATION: "يجب أن لا يزيد حجم الصورة عن 5 ميجابايت",
  IMAGE_EXTENTION_VALIDATION: "يجب أن يحتوي هذا الحقل على صورة بإمتدادات أخرى",
  EXCEL_EXTENTION_VALIDATION:"يجب أن يحتوي هذا الحقل علي ملف Excel",
  PAID:"تم الدفع",
  NOT_PAID:"لم يتم الدفع",
  NOT_FOUND_FEES:"لا يوجد رسوم",
  FOUND_FEES:"يوجد رسوم",
  ONLINE_PAYMENT:"دفع اونلاين (الكترونيا)",
  OFFLINE_PAYMENT:"دفع اوفلاين (رفع ايصال)",
  FAWRY:"فورى",
  BANK:"بنك",
  PAYMENT_METHOD:"طريقة الدفع",
  APPLICATION_FEES_VALUE:"قيمة رسوم الطلب",
  DELETE_FEE_CONFIRM:"هل تريد حذف عملية دفع رسوم الطلب رقم {applicationId} للطالب {studentName} صاحب رقم هوية {idCard}؟",
  PAY:"دفع",
  REPORT_APP_STATUS:"تقرير حالة طلب التقديم",
  APP_STATUS_LIST_TITLE:"الطلاب {status} العام الاكاديمي {year} الفصل {semester}",
  APPLIED_STUDENT_REPORT_TITLE:"تقرير الطلاب المتقدمين",
  STATISTIC_APP_NAME:'إحصائيه عامة للنظام ',
  PROCESSING:"جاري معالجة البيانات",
  PULLING_STEPPER: {
    NEXT: "التالي",
    PREVIOUS: "السابق",
    SELECT: "إختر الحقل المناظر",
    ERROR: "يوجد بيانات غير صحيحة يجب مراجعتها اولاً",
    SHOW: "إستعراض البيانات",
    MSG: "الرسالة",
    CONFIRM_ERROR:"يوجد بيانات غير صحيحة هل تريد حفظ البيانات الصحيحة فقط ؟",
    EXSIT:"يوجد بيانات محفوظة لحقول الشهادة",
    REUSE:"استخدام بيانات الحقول السابقة",
    CONSTRAIN:"يجب إدخال الحقول التالية ",
    FILE_NAME:"البيانات الغير محفوظة",
    CANCEL_MSG:"هل بالفعل تريد إلغاء السحب ؟",
    MORE_DATA:" يوجد {num} بيان أخر ",
    FILE_SIZE:"يجب إلا يزيد حجم الملف عن 6 ميجا ",
    ROWS_COUNT:"يجب أن يحتوي الملف على صفين على الأقل",
    RULES:{
      MAX:"{field} يجب إلا يكون أكبر من {max}",
      MIN:"{field} يجب إلا يقل عن {min}",
      INTEGER:"{field} يجب أن يكون عدد صحيح",
      FLOAT:"{field} يجب أن يكون عدد كسري",
      VALID_COURSE:"يجب إدخال قمية المقررات بقيمة صحيحة"
    },
    STEP1:{
      NAME:"بيانات الشهادة"
    },
    STEP2:{
      NAME:"رفع الملف",
      INFO:"يجب إختيار الملف بأحدي الأمتدادات التالية (xls|.xlsx.) كما يجب ألا يزيد حجم الملف عن 6 ميجا وألا يقل عدد الصفوف عن صفين ",
      FILE_PLACEHOLDER:"إختر الملف",
      FILE:"الملف",
      SHOW_DATA:"إستعراض البيانات"
    },
    STEP3:{
      NAME:"إختر الحقول"
    },
    STEP4:{
      NAME:"تأكيد الحفظ",
      MSG:"بيانات لم يتم حفظها"
    }
  },
  ORDER:"#",
  UPDATE_APPLY:"تعديل حالة الطلب الى ",
  EVENT_NAME: "اسم الحدث",
  START_DATE:"تاريخ البداية",
  END_DATE:"تاريخ النهاية",
  CALENDAR_PERIODS:"فترات التقويم",
  EDIT_STUDENT_DATA:"تعديل بيانات الطلاب",
  NEWS:"الاخبار",
  NEW_TEXT_ARABIC:"نص الخبر عربى",
  NEW_TEXT_ENGLISH:"نص الخبر انجليزى",
  ORDER_NEW:"ترتيب الخبر",
  WITH_COURSE_NAME:"بحث بإسم المقرر",
  CONF_ACCEPT_APP:"هل تريد قبول الطلب؟",
  CONF_ACCEPT_APP_NEGLECT_REFUSE_OTHER:"هل تريد قبول الطلب الحالى ورفض الطلبات الأخرى للطالب على اى كلية اخرى ؟",
  CONF_ACCEPT_APP_NEGLECT_OTHER:"هل تريد قبول الطلب مع العلم ان للطالب طلبات اخرى تم قبولها بكلية اخرى ؟",
  CONF_REFUSE_APP:"هل تريد رفض الطلب؟",
  DATE_FROM_TO_VALIDATION:"يجب أن يكون تاريخ النهاية أكبر من تاريخ البداية",
  ADD_TO_TOTAL:"درجة المقرر مضافة على مجموع الشهادة",
  COURSE_DEGREE_MAX:"المجموع  يجب أن لا يزيد عن 999999.99",
  FLOAT_NUMBER_VALIDATION:"يجب أن لا يزيد الرقم بعد العلامة العشرية عن رقمين",
  CONVERT_APPLICATION_CHECK:"إرسال إلى الطالب للتعديل",
  SEND_MAIL_CHECK:"إرسال ايميل شخصى للطالب بالتعديل",
  SERVICES_LINKS: "روابط الخدمات",
  SERVICE_TYPE:"نوع الخدمة",
  SERVICE_IP:"IP الخدمة",
  SERVICE_URL:"رابط الخدمة",
  SERVICE_DESCRIPTION:"توصيف الخدمة",
  STUDENTS_MAILS:"رسائل بريد الطلاب ",
  SUBJECT_MESSAGE:"موضوع الرسالة",
  TEXT_MESSAGE:"نص الرسالة",
  SHOURTCUTS:"الاختصارات",
  CATEGORY_MESSAGE:"تصنيف الرسالة",
  SEND_MAIL_MESSAGE:"ارسال بريد للطالب",
  
  IP_VALIDATION:"أدخل عنوان ال IP بشكل صحيح",
  URL_VALIDATION:"أدخل الرابط بشكل صحيح",
  COURSE_TOTAL:"الدرجة الكلية للمقرر",
  SCOPE_LABEL: "المجال: ",
  IP_INFO_HINT: "ال IP يجب أن يبدأ ب //:http أو //:https ويمكن أن ينتهي بـ port مكون من 4 أو 5 أرقام وغير مسموح بال / ",
  URL_INFO_HINT: "ال URL يجب أن يبدأ ب //:http أو //:https ويمكن أن ينتهي بـ port مكون من 4 أو 5 أرقام وغير مسموح بال / كما أن ال URL  ممكن أن يكون ال Domain أو  IP Address",
  COURSE_DEGREE_STATUS:"مضاف للمجموع",
  COURSE_DEGREE_NOT_ADDED:"درجة المقرر غير مضافة عالمجموع",
  COURSE_DEGREE_ADDED:"درجة المقرر مضافة عالمجموع",
  INACTIVE_FACULTY:"الكلية غير مفعلة",
  INACTIVE_COURSE:"المقرر غير مفعل",
  CERTIFICATE_NAME_AR: "اسم الشهادة بالعربية",
  CERTIFICATE_NAME_EN: "اسم الشهادة بالإنجليزية",
  NID: "الرقم القومي",
  EMAIL: "البريد الإلكتروني",
  ACCOUNT_STATUS: "حالة الحساب ",
  APPLICATIONS_COUNT:"عدد الطلبات",
  USER_NAME:"اسم المستخدم",
  SEND_NEW_PASSWORD: "إرسال كلمة مرور جديدة",
  NID_VALIDATION: "الرقم القومي غير صحيح",
  EMAIL_VALIDATION:"يجب أن يكون البريد الإلكتروني مثل هذا النموذج user@domain.xxx وألا يزيد اسم الحساب عن 64 حرف",
  MIN_VALIDATION:"يجب أن يكون الرقم 1 على الأقل",
  CONFIRM_CANCEL:"هل تريد الإلغاء و العودة؟",
  CHANGE_PASSWORD:"تغيير كلمة المرور",
  OLD_PASSWORD:"كلمة المرور القديمة",
  NEW_PASSWORD:"كلمة المرور الجديدة",
  CONFIRM_NEW_PASSWORD:"تأكيد كلمة المرور الجديدة",
  PASSWORD_VALIDATION:
      "أدخل كلمة مرور معقدة على الأقل حرف انجليزي كبير ورقم ورمز",
  CONFIRM_PASSWORD_VALIDATION: "كلمة المرور غير متطابقة",
};
export default translationsAr;
