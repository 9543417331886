<template>
  <div class="mb-8">
    <v-card class="mx-auto mb-3">
      <div id="filters">
        <template>
          <v-toolbar flat>
            <v-toolbar-title>
              <p class="mb-0">{{ $t("MANAGE_STUDENTS_DATA") }}</p>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="90vh">
              <v-card outlined>
                <v-form ref="editForm" v-model="valid" lazy-validation>
                  <v-card-title class="justify-center">
                      <span class="headline white--text text-thin">{{
                          formTitle
                        }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-alert v-show="showAlert" dense outlined type="error">{{
                          serverTranslate(errorMessages)
                        }}
                      </v-alert>
                      <v-row>
                        <v-col cols="12">
                          <pr-scope key="componentKey" :scopeFullPath="true"/>
                        </v-col>
                        <v-text-field
                            append-icon="mdi-card-account-details-outline"
                            v-model="editedStudentItem.nId"
                            :rules="NIDRulse"
                            :label="$t('NID')"
                            :maxlength="14"
                            :counter="14"
                            required
                            outlined
                            color="primary"
                            dense
                            ref="nID"
                            @keydown.space="preventLeadingSpace"
                        ></v-text-field>
                        <v-text-field
                            append-icon="mdi-at"
                            :label="$t('EMAIL')"
                            required
                            outlined
                            color="primary"
                            dense
                            @keydown.space="preventLeadingSpace"
                            v-model="editedStudentItem.email"
                            ref="email"
                            :rules="emailRules"
                        ></v-text-field>

                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" dark @click="save()">{{
                        $t("SAVE")
                      }}
                    </v-btn>

                    <v-btn color="darken3" class="white--text" @click="close">{{
                        $t("CANCEL")
                      }}
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
          </v-toolbar>


          <v-form ref="form">
            <v-container>
              <v-row>
                <v-col cols="12" class="pa-0 my-0" xs="12" sm="12" md="12" lg="12">
                  <v-radio-group class="ma-0" v-model="searchWithId" row>
                    <v-radio :label="$t('STUDENT_NAME')" value="1"></v-radio>
                    <v-radio :label="$t('IDENTITY')" value="2"></v-radio>
                  </v-radio-group>
                </v-col>

                <v-col class="py-0" v-if="searchWithId==='2'" cols="12" xs="12" sm="12" md="12" lg="12">
                  <v-text-field
                      v-model="identityNum"
                      counter="25"
                      :rules="$store.state.Rules.noSpecialLetter"
                      :label="$t('WithIDENTITYTEXT')"
                      outlined
                  ></v-text-field>
                </v-col>

                <v-col class="py-0"
                       v-if="searchWithId==='3'" cols="12" xs="12" sm="12" md="12" lg="12">
                  <v-text-field
                      v-model="appId"
                      counter="25"
                      :rules="$store.state.Rules.noSpecialLetter"
                      :label="$t('WITHAPPID')"
                      outlined
                  ></v-text-field>
                </v-col>


                <v-col class="py-0"
                       v-if="searchWithId==='1'"
                       cols="12" xs="12" sm="12" md="12" lg="12">
                  <v-autocomplete
                      :items="students"
                      v-model="studentId"
                      item-value="id"
                      item-text="name"
                      outlined
                      :loading="searchIsLoading"
                      :search-input.sync="studentSearch"
                      return-object
                      no-filter
                      clearable
                      :label="$t('WithNAMETEXT')"
                  ></v-autocomplete>
                </v-col>

              </v-row>
              <v-card-actions class="pa-0">
                <v-container class="px-0">
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-col cols="auto" class="pa-1">
                      <v-btn color="primary" dark @click="searchStudents">
                        {{ $t("SEARCH") }}
                      </v-btn>

                    </v-col>
                    <v-col class="pa-1 mx-2" cols="auto">
                      <v-btn
                          color="darken3"
                          class="white--text"
                          @click="reset"
                      >
                        {{ $t("RESET") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-actions>
            </v-container>
          </v-form>
        </template>
      </div>
    </v-card>
    <v-card class="mx-auto" outlined>
      <div id="inspire">

        <v-data-table
            :headers="headers"
            :items="studentsData"
            class="elevation-1 pointer"
            :footer-props="{
               showFirstLastPage: true,
               firstIcon: 'mdi-arrow-collapse-left',
               lastIcon: 'mdi-arrow-collapse-right',
               prevIcon: 'mdi-arrow-left',
               nextIcon: 'mdi-arrow-right',
               itemsPerPageOptions: [5, 10, 15, 50, 100]
               }"
            :items-per-page="5"
            :loading="loading"
            :options.sync="appOptions"
            :server-items-length="total"
            :hide-default-footer="studentsData.length > 0 ? false : true"
        >
          <template v-slot:item.studentName="{ item }">
            {{ serverTranslate(item.studentName) }}
          </template>

          <template v-slot:item.accountStatus="{ item }">
            {{
              item.accountStatus == true ? $t("ACTIVE") : $t("NOT_ACTIVE")
            }}
          </template>

          <template v-slot:no-data>
            <v-alert icon="warning" class="mt-2" color="light5">
              {{ $t("NO_DATA") }}
            </v-alert>
          </template>
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title> {{ title }}</v-toolbar-title>
            </v-toolbar>
          </template>
          <template v-slot:footer.page-text="props">
            {{ props.pageStart }} - {{ props.pageStop }} {{ $t('PAGE_TEXT') }} {{ props.itemsLength }}
          </template>

          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon meduim color="primary" @click="editStudentData(item)"
                  >mdi-pencil
                  </v-icon
                  >
                </v-btn>
              </template>
              <span>{{ $t("EDIT") }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon
                      meduim
                      color="delete"
                      class="mx-2"
                      @click="deleteUser(item)"
                  >mdi-delete
                  </v-icon
                  >
                </v-btn>
              </template>
              <span>{{ $t("DELETE") }}</span>
            </v-tooltip>
          </template>

        </v-data-table>
      </div>
    </v-card>
    <pr-ApplicationDetails/>
    <pr-scope :key="componentKey" v-if="showScopeTree == true" v-on:setScope="onScopeChanged" :scopeFullPath="false"/>
    <pr-confirmDialog ref="confirm"></pr-confirmDialog>
  </div>
</template>

<script>
import serverTranslations from "@/mixins/serverTranslations";
import ShowApplicationDetails from "@/components/modules/studentData/ManageApplications/ShowApplicationDetails";
import scope from "@/components/shared/ScopeTree.vue"
import {EventBus} from "@/plugins/bus";
import ConfirmDialog from "@/components/shared/ConfirmDialog.vue";
import globalMethodsMixin from "@/mixins/globalMethods.ts";

export default {
  name: "List",
  components: {
    "pr-scope": scope,
    "pr-ApplicationDetails": ShowApplicationDetails,
    "pr-confirmDialog": ConfirmDialog
  },
  mixins: [serverTranslations, globalMethodsMixin],

  mounted() {
    this.searchStudents(true);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const mv = this;
    EventBus.$on("updateRunListAfterAction", () => {
      mv.searchStudents();
    });
  },
  watch: {
    searchWithId() {
      this.studentId = null;
      this.students = [];
      this.studentSearch = "";
      this.identityNum = "";
    },
    appOptions: {
      handler() {
        this.searchStudents();
      },
      deep: true,
    },
    studentSearch(val) {
      if (!val || val.length < 3) {
        this.students = [];
        return false;
      }
      if (this.searchIsLoading) return;
      const phraseLang = this.isRTL(val) ? "ar" : "en"
      const data = {searchData: val}
      this.searchIsLoading = true;
      this.$http.post("/api/students_admission_apps/search_student_user/", data)
          .then(
              res => {
                res = res.data.data;
                this.students = res.students.map(item => {
                  return {"id": item.studentId, "name": this.serverTranslateCustom(item.studentName, phraseLang)}
                });
              },
              err => {
                this.showToasted("error", err.response.data.message)
              }
          )
          .then(() => (this.searchIsLoading = false));

    },
  },
  computed: {
    formTitle() {
      return this.$t("EDIT_STUDENT_DATA");
    },
    isSearchedWithName() {
      return this.searchWithId === "1";
    },
    isSearchedWithAppId() {
      return this.searchWithId === "3";
    },
    title() {
      return this.$t('USERS_LIST_TITLE',);
    },
    headers() {
      return [
        {
          text: this.$t("STUDENT_NAME"),
          align: "center",
          sortable: true,
          value: "studentName"
        },
        {
          text: this.$t("IDENTITY"),
          align: "center",
          sortable: false,
          value: "identityNum"
        },
        {
          text: this.$t("EMAIL"),
          align: "center",
          sortable: false,
          value: "studentEmail"
        },
        {
          text: this.$t("ACCOUNT_STATUS"),
          align: "center",
          sortable: false,
          value: "accountStatus"
        },

        {
          text: this.$t("APPLICATIONS_COUNT"),
          align: "center",
          sortable: true,
          value: "appCount"
        },
        {
          text: this.$t("USER_NAME"),
          align: "center",
          sortable: true,
          value: "userName"
        },
        {
          text: this.$t("ACTIONS"),
          value: "actions",
          sortable: false
        }
      ];
    }
  },
  data() {
    return {
      NIDRulse: this.$store.state.Rules.NIDRulse,
      emailRules: this.$store.state.Rules.emailRules,
      requiredRules: this.$store.state.Rules.requiredRules,
      editedStudentItem: {
        stuId: "",
        nId: "",
        email: "",
      },
      currentItem: {
        stuId: "",
        nId: "",
        email: "",
      },
      errorMessages: "",
      showAlert: false,
      dialog: false,
      valid: true,
      loading: false,
      scopes: [],
      studentSearch: "",
      searchWithId: "1",
      searchWithAppId: "1",
      searchIsLoading: false,
      students: [],
      studentsData: [],
      identityNum: "",
      appId: "",
      total: 0,
      appOptions: {},
      studentId: "",
      showScopeTree: false,
      componentKey: 0
    };
  },
  methods: {
    close() {
      this.$refs.form.resetValidation();
      this.dialog = false;
      this.showAlert = false;
      this.errorMessages = "";
    },

    save() {
      if (this.currentItem.nId === this.editedStudentItem.nId && this.currentItem.email === this.editedStudentItem.email) {
        this.errorMessages = 'لا يوجد اى تعديل |no update found';
        this.showAlert = true;
      } else {
        if (this.$refs.editForm.validate()) {
          this.errorMessages = '';
          this.showAlert = false;
          this.updateStudentData();
        }
      }

    },

    updateStudentData() {
      const stuData = {'editedData': this.editedStudentItem, 'oldData': this.currentItem}
      this.$http.put(
          "/api/students_admission_apps/edit_student_user_data/",
          stuData
      ).then(
          res => {
            this.showToasted("success", res.data.message);
            this.close();
            this.searchStudents(true);
          },
          err => {
            this.errorMessages = err.response.data.message;
            this.showAlert = true;
            this.searchStudents(true);
          }
      );
    },

    editStudentData(studentItem) {
      this.dialog = true;
      this.editedStudentItem.nId = studentItem.identityNum
      this.editedStudentItem.stuId = studentItem.studentId
      this.editedStudentItem.email = studentItem.studentEmail
      this.currentItem.nId = studentItem.identityNum
      this.currentItem.stuId = studentItem.studentId
      this.currentItem.email = studentItem.studentEmail

    },

    deleteUser(item) {
      this.$refs.confirm
          .open("CONFIRMATION_MESSAGE", "DELETE_CONFIRMATION")
          .then(confirm => {
            if (confirm) {
              const id = {studentId: JSON.stringify(item.studentId)};
              this.$http.delete(`/api/students_admission_apps/delete_student_user/${JSON.stringify(item.studentId)}`).then(
                  res => {
                    this.showToasted("success", res.data.message)
                    this.initializeList()
                        .then(data => {
                          this.studentsData = data.items;
                          this.total = data.total;
                        });
                  },
                  err => {
                    this.showToasted("error", err.response.data.message)
                    if (err.response.status === 404) {
                      this.initializeList()
                          .then(data => {
                            this.studentsData = data.items;
                            this.total = data.total;
                          });
                    }
                  }
              );
            }
          });

    },

    showScopeTreeFn() {
      this.showScopeTree = true;
      this.componentKey += 1;
    },

    initializeList() {
      return new Promise((resolve, reject) => {
        const data = this.searchedData();
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.$http
              .post("/api/students_admission_apps/list_student_users/", data)
              .then(
                  res => {
                    this.studentsData = [];
                    const items = res.data.data.results;
                    const total = res.data.data.total;
                    resolve({
                      items,
                      total,
                    });
                  },
                  err => {
                    this.studentsData = [];
                    this.$toasted.show(this.serverTranslate(err.response.data.message),
                        {
                          type: "error",
                          icon: "error_outline",
                          position: "bottom-left",
                          duration: 5000,
                          theme: "bubble"
                        }
                    );
                  }
              );
          this.loading = false;
        }, 1000);
      });
    },
    searchedData() {
      this.loading = true;
      const {sortBy, sortDesc, page, itemsPerPage} = this.appOptions
      const data = {
        studentId: !this.studentId || !this.studentId.id ? "" : this.studentId.id,
        identityType: this.searchWithId || "",
        searchText: this.identityNum || this.appId || "",
        page: page,
        pageSize: itemsPerPage
      }
      if (sortBy.length === 1 && sortDesc.length === 1) {
        data.orderType = sortDesc[0] ? "asc" : "desc";
        data.orderBy = sortBy[0]
      }
      return data
    },

    searchStudents(resetPageNum = false) {
      if (resetPageNum) {
        this.appOptions.page = 1;
        this.appOptions.sortBy = [];
        this.appOptions.sortDesc = [];
      }
      this.initializeList()
          .then(data => {
            this.studentsData = data.items;
            this.total = data.total;
          });
    },

    reset() {
      this.studentId = null;
      this.students = [];
      this.identityNum = "";
      this.searchWithId = "1";
      this.studentSearch = "";
      this.searchStudents(true);
    },
    onScopeChanged(changed) {
      if (changed)
        this.searchStudents(true);
    },
  }
};
</script>
